import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'presskit',
  templateUrl: './presskit.component.html',
  styleUrls: ['./presskit.component.scss'],
})
export class PressKitComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
