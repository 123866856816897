<div id="home" class="home clr-row clr-align-items-center clr-justify-content-around">
    <div class="home">
        <div class="home-container">
            <img class="logo" src="assets/logo.png">
        </div>
        <div class="blur"></div>
        <img class="characters" src="assets/aw_characters.png">
        <div class="home-content clr-col-10">
            <h1 class="release-label">EARLY ACCESS OUT ON STEAM!</h1>
            <button class="btn btn-danger"
                onClick="window.open('https://store.steampowered.com/app/1554050/Ad_Wars/')">PLAY IT NOW</button>
        </div>
    </div>
</div>