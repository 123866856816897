import { NgModule } from '@angular/core';

import { LandingRoutingModule } from './landing-routing.module';

import { LandingComponent } from './landing.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { VideoComponent } from './video/video.component';
import { ContactComponent } from './contact/contact.component';
import { PressKitComponent } from './presskit/presskit.component';
import { DefaultComponent } from './default/default.component';
import { ClarityModule } from '@clr/angular';

@NgModule({
  declarations: [
    LandingComponent,
    HomeComponent,
    AboutComponent,
    PressKitComponent,
    ContactComponent,
    VideoComponent,
    DefaultComponent,
  ],
  imports: [LandingRoutingModule, ClarityModule],
  providers: [],
})
export class LandingModule {}
