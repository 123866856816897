<footer>
    <ul>
        <li><a href="https://twitter.com/adwarsgame" target="_blank"><img src="assets/twitter.svg" width="40px" /></a>
        </li>
        <li><a href="https://www.patreon.com/aglitch" target="_blank"><img src="assets/patreon.svg" width="40px" /></a>
        </li>
        <li><a href="https://www.youtube.com/channel/UCE371RpIvg_KrF4QaeHMJLQ" target="_blank"><img
                    src="assets/youtube.png" width="40px" /></a>
        <li><a href="https://www.instagram.com/adwars_/" target="_blank"><img src="assets/instagram.svg"
                    width="40px" /></a></li>
        <li><a href="https://www.facebook.com/adwarsgame/" target="_blank"><img src="assets/facebook.png"
                    width="40px" /></a></li>
    </ul>
</footer>