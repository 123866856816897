import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { RedirectGuard } from './redirect-guard.module';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
    loadChildren: () =>
      import('./landing/landing-routing.module').then(
        (m) => m.LandingRoutingModule
      ),
  },
  {
    path: 'wishlist',
    pathMatch: 'full',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://store.steampowered.com/app/1554050/Ad_Wars/',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
