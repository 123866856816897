import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ClarityModule } from '@clr/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { YouTubePlayerModule } from '@angular/youtube-player';

import { RedirectGuard } from './redirect-guard.module';
import { LandingModule } from './landing/landing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    YouTubePlayerModule,
    LandingModule,
  ],
  providers: [RedirectGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
