<section id="about">
    <div class="intro-box clr-col-10 clr-col-md-7">
        <h1>What is Ad Wars?</h1>
        <p>Ad Wars is a multi-genre adventure game that takes place on a post-adpocalyptic universe.<br></p><br>
        <h2>Wait, did you say multi-genre?</h2>
        <p>That's correct! Ad Wars is a multi-genre adventure with different difficulty levels that welcomes newcomers
            and hardcore
            players alike.<br><br> Divided into 3 episodes, you'll find yourself jumping and shooting the mischievous
            yet
            endearing Gengens
            in Super Freemium Lands, taking on all sorts of monsters RPG-style in Clickbait Kingdom, and unearthing the
            mysteries of
            Download Highways, a screwball Maze Crawler section. Oh, and did we mention there's also bullet hell
            mania?<br><br>
            If there's
            one thing we encourage the player to have to fully savor Ad Wars, it's an open mind. Traditional -and
            perhaps
            untraditional at times- way of thinking might get you to a certain point, but bizarre logic could be what
            gets you far.
        </p><br>
        <h2>That sounds cool! What's the story behind it?</h2>
        <p>Fate dealt humanity a swift and merciless hand one day. In what came to be known as the Ad-Pocalypse, anti-ad
            forces
            fought relentlessly against Ad Corporations but were completely decimated. Now, overrun by ads, the world is
            far from
            what it used to be, and it's up to no other than Alt, the sole survivor of this cataclysm, to wage a last
            stand and free
            the world from the bane of ads once and for all. <br>
            Ad Wars is set on the internet, where portals and
            vortexes
            are
            commonplace. Be prepared, it won't take just a few data packets to end this quest; the virtual road ahead is
            not just
            perilous, but long. Alt, however, won't be alone; with the special guidance of the trusty and quirky Magna,
            whacky
            characters joining in, and, of course, your help, his chances couldn't be better!
        </p>
    </div>
</section>