<clr-main-container>
    <clr-header class="header header-6">
        <div class="header-nav mat-toolbar-links" [clr-nav-level]="1">
            <a href="#home" class="nav-link"><span i18n class="nav-text">HOME</span></a>
            <a href="#about" class="nav-link"><span i18n class="nav-text">ABOUT</span></a>
            <a href="#video" class="nav-link"><span i18n class="nav-text">TRAILER</span></a>
            <a href="/press-kit" class="nav-link"><span i18n class="nav-text">CONTENT CREATORS</span></a>
        </div>
    </clr-header>

    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>